import React, { useMemo, useState } from 'react'
import { Modal as BootstrapModal, Button, Input } from 'reactstrap'
import {
	MissionGuide as MissionGuidePDF,
	setFonts,
	getPdf,
	getPdfName,
	Investigation,
	useLoadPdfDependencies,
} from '@mission.io/mission-guide'
import { toast } from 'react-toastify'
import { MediaType, variantTypes } from '@mission.io/question-toolkit'
import { AutomatedSimulation, Standard, StandardScope } from '../../types/AutomatedSimulation'
import { getInvestigationInfoForMissionGuides } from './helpers/algorithms'
import { convertToMarkdownString } from '@mission.io/question-views'
import type {
	CreativeCanvasDataWithoutMap,
	CreativeCanvasIssue,
	CreativeCanvasIssueWithTeams,
	CreativeCanvasStationData,
	LiteracyEventAction,
	SingleLiteracyEventDataAppearance,
} from '@mission.io/mission-toolkit/actions'
import { config } from '../../config'
import {
	COLLABORATIVE_CULMINATING_MOMENT,
	LITERACY_EVENT,
} from '@mission.io/mission-toolkit/constants'
import { ContextualDataEntryBaseWithAppearance } from '@mission.io/mission-toolkit'

type ModalProps = {
	simulation: AutomatedSimulation
	onRequestClose: () => void
	isOpen: boolean
}
setFonts({
	normal: '/fonts/ProximaNova/ProximaNova-Regular.ttf',
	semibold: '/fonts/ProximaNova/ProximaNova-Semibold.ttf',
	bold: '/fonts/ProximaNova/ProximaNova-Bold.ttf',
	extrabold: '/fonts/ProximaNova/ProximaNova-Extrabld.ttf',
	italic: '/fonts/ProximaNova/ProximaNova-RegularIt.ttf',
	boldItalic: '/fonts/ProximaNova/ProximaNova-BoldIt.ttf',
	headerNormal: '/fonts/Orbitron/Orbitron-Regular.ttf',
	headerBold: '/fonts/Orbitron/Orbitron-Bold.ttf',
	headerExtraBold: '/fonts/Orbitron/Orbitron-ExtraBold.ttf',
})

/**
 * Get the investigations to show in the mission guide for the given simulation
 */
function getInvestigationsFromSimulation(simulation: AutomatedSimulation): Investigation[] {
	const investigations: Array<Investigation> = []
	if (!simulation.initialScreenActionId) {
		return investigations
	}

	for (const { investigationActionId, mapId } of getInvestigationInfoForMissionGuides(
		simulation.actions,
		simulation.initialScreenActionId
	)) {
		const investigationAction = simulation.actions[investigationActionId]
		if (!investigationAction) {
			continue
		}

		if (investigationAction.type === 'CULMINATING_MOMENT_SCREEN') {
			investigations.push({
				question: {
					type: variantTypes.MULTIPLE_CHOICE,
					phrase: convertToMarkdownString(investigationAction.questionInfo.phrase),
					choices: investigationAction.questionInfo.options.map((option) => ({
						_id: option._id,
						text: convertToMarkdownString(option.text),
						answer: option.correct,
					})),
					media: investigationAction.questionInfo.media.map((m) => {
						if (m.type !== 'IMAGE') {
							throw new Error('Only images are supported in culminating moments')
						}
						return { ...m, type: MediaType.IMAGE }
					}),
					// Properties required by flow
					_id: investigationAction._id,
					default: false,
					version: 1,
				},
				mapIds: mapId ? new Set([mapId]) : new Set(),
				type: investigationAction.type,
			})
		} else if (investigationAction.type === 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN') {
			const creativeCanvasStationAction = simulation.actions[investigationAction.canvasActionId]

			if (
				!creativeCanvasStationAction ||
				creativeCanvasStationAction.type !== 'ACTIVATE_STATION' ||
				creativeCanvasStationAction.stationData.stationId !== 'CREATIVE_CANVAS'
			) {
				continue
			}

			const creativeCanvasStationData: CreativeCanvasStationData<string> =
				creativeCanvasStationAction.stationData
			const sharedCreativeCanvasMaps = new Set<string>()
			const sharedData: CreativeCanvasDataWithoutMap[] = []
			creativeCanvasStationData.data.forEach((data) => {
				if (data.type === 'MAP') {
					sharedCreativeCanvasMaps.add(data.mapId)
				} else {
					sharedData.push(data)
				}
			})

			investigations.push({
				type: investigationAction.type,
				requiredPercentForSuccess:
					investigationAction.ending === COLLABORATIVE_CULMINATING_MOMENT.ENDING.CLASS_VOTE
						? null
						: investigationAction.requiredPercentForSuccess,
				sharedMapIds: sharedCreativeCanvasMaps,
				sharedData: sharedData,
				creativeCanvasActionId: creativeCanvasStationAction._id,
				issues: getIssuesFromCreativeCanvasStationData(creativeCanvasStationData, simulation),
				teacherTips:
					creativeCanvasStationAction.teacherTips?.map((tip) => ({
						title: tip.title,
						text: tip.text,
					})) || [],
			})
		} else if (investigationAction.type === LITERACY_EVENT.SIMULATION_ACTION_TYPE) {
			investigations.push(investigationAction)
		}
	}

	return investigations
}

/**
 * Uses the given `creativeCanvasStationData` to create an array of investigation issues to pass to the mission guide
 *
 * @param creativeCanvasStationData - the creative canvas station data to get the issues from
 * @param simulation - the simulation
 * @returns an array of investigation issues
 */
function getIssuesFromCreativeCanvasStationData(
	creativeCanvasStationData: CreativeCanvasStationData<string>,
	simulation: AutomatedSimulation
) {
	const issues: Array<CreativeCanvasIssueWithTeams<string>> | [CreativeCanvasIssue<string>] =
		'issues' in creativeCanvasStationData
			? creativeCanvasStationData.issues
			: [creativeCanvasStationData.issue]

	return issues.map((creativeCanvasIssue) => {
		const issueMaps = new Set<string>()
		const issueData: CreativeCanvasDataWithoutMap[] = []
		creativeCanvasIssue.data.forEach((data) => {
			if (data.type === 'MAP') {
				issueMaps.add(data.mapId)
			} else {
				issueData.push(data)
			}
		})

		return {
			prompt: creativeCanvasIssue.prompt,
			canvasBackgroundMedia: creativeCanvasIssue.backgroundMedia || null,
			teams:
				'teams' in creativeCanvasIssue
					? creativeCanvasIssue.teams
							.map((teamId) => {
								const team = simulation.teams.find((team) => team._id === teamId)
								return team ? { id: team._id, name: team.name } : undefined
							})
							.filter((t): t is { id: string; name: string } => !!t)
					: undefined,
			requiredScore: creativeCanvasIssue.rubric.requiredScore,
			criteria: creativeCanvasIssue.rubric.criteria.map((criterion) => ({
				_id: criterion._id,
				text: criterion.text,
				maxScore: Math.max(...criterion.gradingOptions.map((option) => option.score)),
				required: criterion.required,
				displayType: criterion.displayType,
			})),
			mapIds: issueMaps,
			data: issueData,
			toolsAvailable: Array.from(
				new Set([...creativeCanvasStationData.canvasTools, ...creativeCanvasIssue.canvasTools])
			),
			teacherTips: creativeCanvasIssue.teacherTips.map((tip) => ({
				title: tip.title,
				text: tip.text,
			})),
		}
	})
}

/**
 * Gets all the extra contextual data that may be added during the given `simulation`
 *
 * @param simulation - the simulation to get the extra contextual data for
 * @returns an array of contextual data entries
 */
function getExtraContextualData(
	simulation: AutomatedSimulation
): Array<ContextualDataEntryBaseWithAppearance> {
	// get all literacy events that will appear as contextual data
	const literacyEvents = Object.values(simulation.actions).filter(
		(
			action
		): action is LiteracyEventAction<string> & {
			dataAppearance: SingleLiteracyEventDataAppearance[]
		} =>
			action.type === LITERACY_EVENT.SIMULATION_ACTION_TYPE && Array.isArray(action.dataAppearance)
	)

	const allTeams = new Set(simulation.teams.map(({ _id }) => _id))

	return literacyEvents.flatMap((literacyEvent) =>
		getContextualDataForLiteracyEvent(literacyEvent, allTeams)
	)
}

/**
 * Gets all contextual data entries for the given `literacyEvent`
 *
 * @param literacyEvent - the literacy event to get the contextual data entries for. It should already be known that the given
 * `literacyEvent` has at least one data appearance.
 * @param allTeams - all the teams in the simulation
 * @returns an array of contextual data entries
 */
function getContextualDataForLiteracyEvent(
	literacyEvent: LiteracyEventAction<string> & {
		dataAppearance: SingleLiteracyEventDataAppearance[]
	},
	allTeams: Set<string>
): ContextualDataEntryBaseWithAppearance[] {
	const mapIdsForLiteracyEvent: Set<string> = new Set()
	const creativeCanvasStationIdsForLiteracyEvent: Set<string> = new Set()
	literacyEvent.dataAppearance.forEach((appearance) => {
		if (appearance.type === LITERACY_EVENT.DATA_APPEARANCE.LOCATION.MAP) {
			mapIdsForLiteracyEvent.add(appearance.mapId)
		} else if (
			appearance.type === LITERACY_EVENT.DATA_APPEARANCE.LOCATION.CREATIVE_CANVAS_STATION
		) {
			creativeCanvasStationIdsForLiteracyEvent.add(appearance.stationActionId)
		}
	})

	const teamsNotAssignedToAnyReadingContext: Set<string> = (() => {
		if (literacyEvent.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL) {
			// All teams are assigned to the general reading context
			return new Set()
		}
		literacyEvent.assignment.teams.forEach(({ teamId }) => {
			allTeams.delete(teamId)
		})
		return allTeams
	})()

	return literacyEvent.readingContexts.map(
		(readingContext): ContextualDataEntryBaseWithAppearance => {
			const availability = (() => {
				if (literacyEvent.assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL) {
					return 'ALL' as const
				}
				const teamsAssignedToReadingContext = literacyEvent.assignment.teams
					.filter((t) => t.readingContexts.includes(readingContext.id))
					.map((t) => t.teamId)
				return {
					teams: [...teamsAssignedToReadingContext, ...teamsNotAssignedToAnyReadingContext],
				}
			})()
			return {
				id: `LE_${literacyEvent._id}_${readingContext.id}`,
				title: readingContext.title,
				text: readingContext.text,
				media: [...readingContext.media],
				relevance: readingContext.relevance,
				icon: null,
				appearance: [
					...Array.from(mapIdsForLiteracyEvent).map((mapId) => ({
						type: 'MAP' as const,
						mapId,
						availability,
					})),
					...Array.from(creativeCanvasStationIdsForLiteracyEvent).map((stationActionId) => ({
						type: 'CREATIVE_CANVAS' as const,
						canvasActionId: stationActionId,
						availability,
					})),
				],
			}
		}
	)
}

/**
 * MissionGuideModal - show the mission guide for the given simulation
 *
 * @param {{simulation: AutomatedSimulation}} simulation - the simulation to show the missionGuide for
 * @param {{onRequestClose: () => void}} onRequestClose - a callback used to close the missionGuide modal
 * @param {{isOpen: boolean}} isOpen - true if the missionGuide should be shown, false otherwise
 *
 * @return {React$Node} - the react component
 */
export function MissionGuideModal({ simulation, onRequestClose, isOpen }: ModalProps): JSX.Element {
	const variants = useMemo(() => getMissionGuideStandardVariants(simulation), [simulation])

	const [variant, setVariant] = useState(variants[0]?.variant)

	const targetedStandards = useMemo(
		() => ({
			standardGroups: variants?.find(({ variant: v }) => v === variant)?.standardGroups || [],
		}),
		[variant, variants]
	)
	const { investigations, extraContextualData } = useMemo(() => {
		return {
			investigations: getInvestigationsFromSimulation(simulation),
			extraContextualData: getExtraContextualData(simulation),
		}
	}, [simulation])

	const { loaded: pdfDependenciesLoaded, errors: pdfDependencyLoadErrors } =
		useLoadPdfDependencies()

	return (
		<BootstrapModal
			size="lg"
			isOpen={isOpen}
			toggle={onRequestClose}
			className="h-[94vh] [&>*]:h-full">
			<MissionGuidePDF
				simulation={simulation}
				onError={(error: Error) => {
					console.error(error)
					onRequestClose()
				}}
				Footer={({ Link }) => {
					return (
						<div className="flex justify-between p-2">
							<Input
								type="select"
								value={variant}
								onChange={(e) => {
									setVariant(e.currentTarget.value)
								}}
								className="w-auto">
								{variants?.map(({ variant }) => (
									<option key={variant} value={variant}>
										{variant}
									</option>
								))}
							</Input>

							<div className="flex gap-2">
								<Button
									onClick={() => {
										if (!pdfDependenciesLoaded) {
											toast.error("Can't generate mission guides. PDF dependencies are not loaded.")
											pdfDependencyLoadErrors.forEach((error) => {
												if (error) {
													toast.error(error.message)
												}
											})
											return
										}
										generateAndUploadMissionGuidesWithToast(simulation)
									}}>
									Upload Mission Guides
								</Button>
								<Link>
									{({ loading, error }) => (
										<Button disabled={loading || !!error} color="primary">
											{loading ? 'Preparing Mission Guide' : 'Download Mission Guide'}
										</Button>
									)}
								</Link>
							</div>
						</div>
					)
				}}
				targetedStandards={targetedStandards}
				investigations={investigations}
				extraContextualData={extraContextualData}
			/>
		</BootstrapModal>
	)
}

export async function generateAndUploadMissionGuidesWithToast(simulation: AutomatedSimulation) {
	const toastId = toast.info('Calculating Mission Guides to Generate...', {
		autoClose: false,
		position: 'bottom-right',
		closeButton: false,
		closeOnClick: false,
	})

	await generateAndUploadMissionGuides(simulation, {
		onUpdate: (update) => {
			if (update.status === 'error') {
				toast.update(toastId, {
					type: 'error',
					render: 'Error Uploading Mission Guides',
					closeButton: true,
				})
				return
			}
			if (update.status === 'complete') {
				toast.update(toastId, {
					type: 'success',
					render: 'Mission Guides Uploaded Successfully!',
					closeButton: true,
					progress: undefined, // Setting progress to 1 would be ideal, but this makes the toast disappear immediately, so instead just hide the progress bar
				})
				return
			}

			const singlePdf = update.currentPdfs.first === update.currentPdfs.last

			// text in the format "Mission Guides 1-5 of 7 (default, UT, TX, CA, NY)..."
			const missionGuideNumbers = `Mission Guide${singlePdf ? '' : 's'} ${
				update.currentPdfs.first
			}${!singlePdf ? `-${update.currentPdfs.last}` : ''} of ${update.totalPdfs}`

			toast.update(toastId, {
				render: (
					<>
						{update.status === 'generating' ? 'Generating' : 'Uploading'} {missionGuideNumbers} (
						{update.currentPdfs.variants.join(', ')})...
					</>
				),
				progress: (update.currentPdfs.first - 1) / update.totalPdfs,
			})
		},
	})
}

/**
 * Generates and uploads mission guides for the simulation and displays a toast with the status.
 * Note: While the guides are being generated, the site will be unresponsive.
 *
 * @param {Object} simulation - The simulation to generate mission guides for
 * @param options
 * @param {boolean} options.onUpdate - A callback function that is called with the progress of the mission guide generation.
 *                    If this exists, errors will be caught and sent to the callback instead of thrown.
 */
export async function generateAndUploadMissionGuides(
	simulation: AutomatedSimulation,
	{
		onUpdate,
	}: {
		onUpdate?: (
			arg:
				| {
						status: 'generating' | 'uploading'
						currentPdfs: {
							first: number
							last: number
							variants: Array<string>
						}
						totalPdfs: number
				  }
				| { status: 'complete' }
				| { status: 'error' }
		) => void
	} = {}
) {
	try {
		const standardVariants = getMissionGuideStandardVariants(simulation)
		const investigations = getInvestigationsFromSimulation(simulation)
		const extraContextualData = getExtraContextualData(simulation)

		const batchSize = 5
		for (let i = 0; i < standardVariants.length; i += batchSize) {
			const batch = standardVariants.slice(i, i + batchSize)

			onUpdate?.({
				status: 'generating',
				currentPdfs: {
					first: i + 1,
					last: Math.min(i + batchSize, standardVariants.length),
					variants: batch.map(({ variant }) => variant),
				},
				totalPdfs: standardVariants.length,
			})

			const missionGuides = await generateMissionGuides({
				simulation,
				standardVariants: batch,
				investigations,
				extraContextualData,
			})

			onUpdate?.({
				status: 'uploading',
				currentPdfs: {
					first: i + 1,
					last: Math.min(i + batchSize, standardVariants.length),
					variants: batch.map(({ variant }) => variant),
				},
				totalPdfs: standardVariants.length,
			})

			await uploadMissionGuides(simulation, missionGuides)
		}

		onUpdate?.({
			status: 'complete',
		})
	} catch (error) {
		if (onUpdate) {
			onUpdate({ status: 'error' })
		} else {
			throw error
		}
	}
}

/**
 * generate mission guide pdfs for the given `simulation` and the given `standardVariants`.
 * @param simulation - the simulation to generate the mission guides for
 * @param standardVariants - the standard variants to generate mission guides for
 * @param investigations - the investigations to include in the mission guides
 * @return an array of the mission guides, complete with names
 */
async function generateMissionGuides({
	simulation,
	standardVariants,
	investigations,
	extraContextualData,
}: {
	simulation: AutomatedSimulation
	standardVariants: Array<StandardVariant>
	investigations: Array<Investigation>
	extraContextualData: Array<ContextualDataEntryBaseWithAppearance>
}): Promise<Array<File>> {
	const files = await Promise.all(
		standardVariants.map(async ({ variant, standardGroups }) => {
			return new File(
				[
					await getPdf({
						simulation: { ...simulation, maps: simulation.maps || [] },
						targetedStandards: {
							standardGroups,
						},
						variant: variant !== 'default' ? variant : undefined,
						investigations: investigations,
						extraContextualData,
					}).toBlob(),
				],
				getPdfName(simulation.name, variant !== 'default' ? variant : undefined),
				{
					type: 'application/pdf',
				}
			)
		})
	)
	return files
}

/**
 * Uploads the mission guide pdfs for the given simulation to simulations api
 * @param {AutomatedSimulation} simulation - the simulation to upload the mission guides for
 * @param pdfs - the mission guides to upload
 * @return {Promise<void>} - a promise that resolves when the mission guides have been uploaded
 */
async function uploadMissionGuides(
	simulation: AutomatedSimulation,
	pdfs: Array<File>
): Promise<unknown> {
	const formData = new FormData()
	pdfs.forEach((pdf) => {
		formData.append('guides', pdf, pdf.name)
	})
	const result = await fetch(
		`${config.simulationsApiUrl}/api/simulations/${simulation._id}/guides`,
		{
			method: 'PUT',
			body: formData,
			credentials: 'include',
		}
	)

	if (!result.ok) {
		throw new Error(`Failed to upload mission guides: ${result.statusText}`)
	}

	const json = await result.json()
	return json
}

type StandardGroup = {
	name: string
	standards: Array<Standard>
}
type StandardVariant = {
	variant: string
	standardGroups: Array<StandardGroup>
}

/**
 * Gets a list of standard variants for this simulation. There will always be a default variant which includes any national
 * standards. There will also be a variant for each state that has its own unique standard set. Each state variant will include
 * a standard group with all standards for that state, and a standard group with all national standards.
 *
 * Example:
 *   For a simulation with some national standards and some TEKS standards(TX), There will be two variants:
 *    - default: includes all national standards
 *    - Texas: includes a group with all national standards and a group with all TEKS standards
 */
function getMissionGuideStandardVariants(simulation: AutomatedSimulation): Array<StandardVariant> {
	const nationalStandardsGroup = {
		name: 'National Standards',
		standards: simulation.standards.filter(
			(standard) => standard.standardSet.scope === StandardScope.NATIONAL
		),
	}
	const variants = [{ variant: 'default', standardGroups: [nationalStandardsGroup] }]

	const stateStandardGroups: { [state: string]: StandardGroup } = {}

	// go through all standards. if the standard is for a specific state, create a new variant group for that state
	simulation.standards.forEach((standard) => {
		if (standard.standardSet.scope === StandardScope.NATIONAL) {
			// nothing special about this standard
			return
		}

		standard.standardSet.states.forEach(({ abbreviation: state }) => {
			if (!stateStandardGroups.hasOwnProperty(state)) {
				stateStandardGroups[state] = {
					name: `${state} Standards`,
					standards: [],
				}
			}

			// @ts-expect-error TS2532 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			stateStandardGroups[state].standards.push(standard)
		})
	})
	Object.keys(stateStandardGroups).forEach((state) => {
		variants.push({
			variant: state,
			// @ts-expect-error TS2322 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			standardGroups: [stateStandardGroups[state], nationalStandardsGroup],
		})
	})
	return variants
}

export const __exportedForTesting = {
	getIssuesFromCreativeCanvasStationData,
}
