import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { userQuery } from '../helpers/queries'
import styled from 'styled-components'
import { config } from '../config'
import { isObjectWithKey } from '../helpers/functions'
/**
 * LoginWrapper - forces the user to login before the children of this component are displayed
 *
 * @param  {{children:React$Node}} {children}
 */

export default function LoginWrapper({ children }: { children: React.ReactNode }): React.ReactNode {
	const [hasLoggedIn, setHasLoggedIn] = useState(config.skipLogin)
	// only force login once
	const { status, error } = useQuery({
		...userQuery,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		retry: Infinity,
	})
	useEffect(() => {
		if (!hasLoggedIn && status === 'success') {
			setHasLoggedIn(true)
		}
	}, [hasLoggedIn, status])

	if (hasLoggedIn) {
		return children
	}

	return (
		<Screen>
			<h1 className="text-4xl font-bold my-2">Simulation Creator</h1>
			<div className="text-2xl font-bold my-2">Login Needed.</div>
			<div className="text-2xl font-bold my-2">
				If Google authentication does not open in a new tab, check to make sure pop up windows are
				not blocked for this site.
			</div>
			{status === 'error' ? (
				<div className="text-2xl font-bold my-2 text-error">
					An Error Occurred:{' '}
					{isObjectWithKey(error, 'message') && typeof error.message === 'string'
						? error.message
						: 'unknown error'}
				</div>
			) : null}
			<Stars animationTime="8s" />
			<Stars2 animationTime="7s" />
		</Screen>
	)
}

const Stars = styled.div<{ animationTime: string }>`
	background: rgba(128, 0, 128, 0.1) center / 200px 200px round;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	animation: blink ${({ animationTime }) => animationTime} linear alternate infinite;

	background-image: radial-gradient(1px 1px at 15px 15px, white, rgba(255, 255, 255, 0)),
		radial-gradient(1px 1px at 50px 125px, white, rgba(255, 255, 255, 0)),
		radial-gradient(1px 1px at 125px 120px, white, rgba(255, 255, 255, 0)),
		radial-gradient(1.5px 1.5px at 10px 1px, white, rgba(255, 255, 255, 0)),
		radial-gradient(2px 2px at 115px 125px, white, rgba(255, 255, 255, 0)),
		radial-gradient(2.5px 2.5px at 65px 180px, white, rgba(255, 255, 255, 0));

	@keyframes blink {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0.25;
		}
	}
`
const Stars2 = styled(Stars)`
	background-image: radial-gradient(1px 1px at 36px 72px, white, rgba(255, 255, 255, 0)),
		radial-gradient(1px 1px at 16px 93px, white, rgba(255, 255, 255, 0)),
		radial-gradient(1px 1px at 120px 55px, white, rgba(255, 255, 255, 0)),
		radial-gradient(1.5px 1.5px at 125px 83px, white, rgba(255, 255, 255, 0)),
		radial-gradient(2px 2px at 20px 22px, white, rgba(255, 255, 255, 0)),
		radial-gradient(2.5px 2.5px at 87px 63px, white, rgba(255, 255, 255, 0));
`
const Screen = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: radial-gradient(
		circle at 50vw 100vh,
		rgba(255, 255, 255, 1) 0%,
		rgba(200, 122, 0, 1) 10%,
		rgba(40, 40, 0, 1) 100%
	);
	position: relative;
	padding: 16px;
	font-weight: bold;
	position: relative;
	z-index: 0;
`
