import React from 'react'
import { useDispatch } from 'react-redux'
import {
	Button,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap'
import { actions } from '../../setup/tiles'
import './SimulationImage.css'
import { useQueryClient } from 'react-query'
import { ImageUploadSettings } from '../../types/tiles'
type Props = {
	simulationId: string
	imageUrl: string | null | undefined
	imageThumbnailUrl: string | null | undefined
}

/**
 * Component to display and manage simulation images.
 *
 * @param {Object} props - The component props.
 * @param {string} props.simulationId - The ID of the simulation.
 * @param {string | null | undefined} props.imageThumbnailUrl - The URL of the image thumbnail.
 * @param {string | null | undefined} props.heroImageUrl - The URL of the hero image.
 * @returns {JSX.Element} The rendered component.
 */
export default function SimulationImageSelection({
	simulationId,
	imageThumbnailUrl,
	imageUrl,
}: Props): JSX.Element {
	const [showImageSelectionModal, setShowImageSelectionModal] = React.useState(false)

	return (
		<div className="border rounded-lg p-3 flex flex-row justify-between gap-[16px] mb-3">
			<ImageView
				url={imageThumbnailUrl}
				label="Image"
				formText="Thumbnail Image for this simulation."
			/>
			<ImageView url={imageUrl} label="Hero Image" formText="Hero image for this simulation." />

			<Button className="h-fit" onClick={() => setShowImageSelectionModal(true)}>
				Change Image
			</Button>
			<ImageSelectionModal
				isOpen={showImageSelectionModal}
				onClose={() => setShowImageSelectionModal(false)}
				simulationId={simulationId}
			/>
		</div>
	)
}

/**
 * ImageView component displays an image with a label and form text.
 * If the image URL is provided, it shows the image; otherwise, it displays a placeholder text.
 *
 * @param {Object} props - The properties object.
 * @param {string | null | undefined} props.url - The URL of the image to display. If null or undefined, a placeholder text is shown.
 * @param {string} props.label - The label text to display above the image.
 * @param {string} props.formText - The form text to display below the label.
 * @returns {JSX.Element} The rendered ImageView component.
 */
const ImageView = ({
	url,
	label,
	formText,
}: {
	url: string | null | undefined
	label: string
	formText: string
}): JSX.Element => {
	return (
		<div className="flex-column">
			<Label>{label}</Label>
			<FormText>{formText}</FormText>

			<div className="mb-2">
				{url && (
					<div>
						<div className="max-w-xs">
							<img src={url} alt="name" />
						</div>
					</div>
				)}
				{!url && <i>No image uploaded</i>}
			</div>
		</div>
	)
}

/**
 * A checkbox component for toggling image settings.
 *
 * @param {Object} props - The component props.
 * @param {ImageUploadSettings} props.imageSettings - The current image settings.
 * @param {function} props.setImageSettings - Function to update the image settings.
 * @param {'thumbnail' | 'hero'} props.imageInputType - The type of image input, either 'thumbnail' or 'hero'.
 *
 * @returns {JSX.Element} The rendered checkbox component.
 */
const ImageInputCheckbox = ({
	imageSettings,
	setImageSettings,
	imageInputType,
}: {
	imageSettings: ImageUploadSettings
	setImageSettings: (newSettings: ImageUploadSettings) => void
	imageInputType: 'thumbnail' | 'hero'
}) => {
	return (
		<div className="flex-row m-2">
			<Input
				className="mr-2"
				id={`${imageInputType}Check`}
				type="checkbox"
				checked={imageSettings[imageInputType]}
				onChange={(e) => {
					setImageSettings({ ...imageSettings, [imageInputType]: e.target.checked })
				}}
			/>
			<label htmlFor={`${imageInputType}Check`}>{imageInputType} image</label>
		</div>
	)
}

/**
 * ImageSelectionModal component allows users to select and upload images for a simulation.
 * The user can select if they are uploading a picture to be used as a thumbnail or a hero image or both.
 *
 * @param {Object} props - The component props.
 * @param {string} props.simulationId - The ID of the simulation for which the image is being uploaded.
 * @param {boolean} props.isOpen - A boolean indicating whether the modal is open or not.
 * @param {Function} props.onClose - A function to be called when the modal is closed.
 *
 * @returns {JSX.Element} The rendered ImageSelectionModal component.
 */
const ImageSelectionModal = ({
	simulationId,
	isOpen,
	onClose,
}: {
	simulationId: string
	isOpen: boolean
	onClose: () => void
}) => {
	const [selectedImage, setSelectedImage] = React.useState<File | null | undefined>(null)
	const [imageUploadSettings, setImageUploadSettings] = React.useState<ImageUploadSettings>({
		thumbnail: false,
		hero: false,
	})
	const dispatch = useDispatch()
	const queryClient = useQueryClient()

	return (
		<Modal isOpen={isOpen} toggle={onClose}>
			<ModalHeader toggle={onClose}>Select Image</ModalHeader>
			<ModalBody>
				<div className="flex-row">
					<div className="flex-column">
						<h3>Upload image for:</h3>
						<ImageInputCheckbox
							imageSettings={imageUploadSettings}
							setImageSettings={setImageUploadSettings}
							imageInputType="thumbnail"
						/>
						<ImageInputCheckbox
							imageSettings={imageUploadSettings}
							setImageSettings={setImageUploadSettings}
							imageInputType="hero"
						/>
					</div>
					<input
						id={'imageSelectionId'}
						name={'Image Selection'}
						type="file"
						accept="image/*"
						className="form-control-file mb-2"
						onChange={(e) => {
							if (e.target.files && e.target.files.length > 0) {
								e.persist()
								setSelectedImage(e.target.files[0])
							}
						}}
					/>
					{selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="name" />}
				</div>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button
					disabled={!selectedImage || (!imageUploadSettings.thumbnail && !imageUploadSettings.hero)}
					color="primary"
					onClick={() => {
						dispatch(
							actions.simulations.putImage({
								simulationId: simulationId,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								image: selectedImage!,
								queryClient,
								updateImageSettings: imageUploadSettings,
							})
						).finally(() => {
							setImageUploadSettings({ thumbnail: false, hero: false })
							setSelectedImage(null)
							onClose()
						})
					}}>
					Confirm
				</Button>
			</ModalFooter>
		</Modal>
	)
}
