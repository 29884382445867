import axios from 'axios'
import update from 'immutability-helper'
import { toast } from 'react-toastify'
import { createTile, createSyncTile } from 'redux-tiles'
import { keyBy } from 'lodash'
import { ClientQuestion, encodeMediaFiles } from '@mission.io/question-toolkit'
import { config } from '../../config'

import type { ClassQuestion, ClassQuestionWithoutId, FullMap, Simulation } from '../../types'
import type { ImageUploadSettings, TileParams } from '../../types/tiles'
import { updateAutomatedSimulationInReactQuery } from '../automatedSimulations/queries'
import type { QueryClient } from 'react-query'
import { isObjectWithKey } from '../../helpers/functions'
const URL = `${config.simulationsApiUrl}/api/simulations`

const onError = (error: unknown) => {
	let message = 'Failed saving simulation changes. Refresh the page to avoid data loss.'

	if (
		isObjectWithKey(error, 'response') &&
		isObjectWithKey(error.response, 'data') &&
		isObjectWithKey(error.response.data, 'message')
	) {
		message += `\n\nMessage: ${String(error.response.data.message)}`
	}

	toast.error(message, {
		autoClose: false,
	})
}

const store = createSyncTile({
	type: ['simulations', 'store'],
	initialState: {},
	fns: {
		add: ({ params: simulation, selectors, getState }) => {
			const currentSimulations = selectors.simulations.store(getState())
			return { ...currentSimulations, [simulation._id]: simulation }
		},
		addMany: ({ params: simulations, selectors, getState }) => {
			const currentSimulations = selectors.simulations.store(getState())
			return { ...currentSimulations, ...keyBy(simulations, '_id') }
		},
		updateQuestions: ({ params, selectors, getState }) => {
			const { id, questions } = params
			const currentSimulations = selectors.simulations.store(getState())
			return { ...currentSimulations, [id]: { ...currentSimulations[id], questions } }
		},
		addMap: ({ params: { id, mapId }, selectors, getState }) => {
			const simulations = selectors.simulations.store(getState())
			return {
				...simulations,
				[id]: update(simulations[id], {
					mapIds: {
						$push: [mapId],
					},
				}),
			}
		},
		deleteMap: ({ params: { id, mapId }, selectors, getState }) => {
			const simulations = selectors.simulations.store(getState())
			return {
				...simulations,
				[id]: update(simulations[id], {
					mapIds: {
						$splice: [[simulations[id].mapIds.indexOf(mapId), 1]],
					},
				}),
			}
		},
		updateMeta: ({ params, selectors, getState }) => {
			const { id, ...payload } = params
			const currentSimulations = selectors.simulations.store(getState())
			return { ...currentSimulations, [id]: { ...currentSimulations[id], ...payload } }
		},
		updateCardMeta: ({
			params,
			selectors,
			getState,
		}: TileParams<{ simulationId: string; cardId: string }>) => {
			const { simulationId, cardId, ...payload } = params
			const currentSimulations = selectors.simulations.store(getState())
			const simulation = Object.assign({}, currentSimulations[simulationId])
			const cardIndex = simulation.deck.cards.findIndex((c) => c._id === cardId)
			const card = simulation.deck.cards[cardIndex]
			if (!card) {
				throw new Error(`Card with id ${cardId} not found`)
			}
			simulation.deck.cards[cardIndex] = { ...card, ...payload }
			return { ...currentSimulations, [simulationId]: { ...simulation } }
		},
		moveCard: ({ params, selectors, getState }) => {
			const { simulationId, sourceIndex, destinationIndex } = params
			const simulations = selectors.simulations.store(getState())
			const simulation = simulations[simulationId]
			const cards = [...simulation.deck.cards]
			const [removed] = cards.splice(sourceIndex, 1)
			cards.splice(destinationIndex, 0, removed)
			return {
				...simulations,
				[simulationId]: { ...simulation, deck: { ...simulation.deck, cards: cards } },
			}
		},
		addCard: ({ params: { simulationId, cardIndex, card }, selectors, getState }) => {
			const simulations = selectors.simulations.store(getState())
			return update(simulations, {
				[simulationId]: {
					deck: {
						cards: {
							$splice: [[cardIndex, 0, card]],
						},
					},
				},
			})
		},
		deleteCard: ({
			params: { simulationId, cardId },
			selectors,
			getState,
		}: TileParams<{ simulationId: string; cardId: string }>) => {
			const simulations = selectors.simulations.store(getState())
			const simulation = simulations[simulationId]
			if (!simulation) {
				throw new Error(`Simulation with id ${simulationId} not found`)
			}
			const cardIndex = simulation.deck.cards.findIndex((card) => card._id === cardId)

			if (cardIndex === -1) {
				return simulations
			}

			return update(simulations, {
				[simulationId]: {
					deck: {
						cards: {
							$splice: [[cardIndex, 1]],
						},
					},
				},
			})
		},
		addQuest: ({ params: { simulationId, locationIndex }, selectors, getState }) => {
			const simulations = selectors.simulations.store(getState())
			let updateObject

			if (!simulations[simulationId].deck.quests) {
				updateObject = {
					$set: [
						{
							locationIndex: locationIndex,
						},
					],
				}
			} else
				updateObject = {
					$push: [
						{
							locationIndex: locationIndex,
						},
					],
				}

			return update(simulations, {
				[simulationId]: {
					deck: {
						quests: updateObject,
					},
				},
			})
		},
		deleteQuest: ({ params: { simulationId, questIndex }, selectors, getState }) => {
			const simulations = selectors.simulations.store(getState())
			return update(simulations, {
				[simulationId]: {
					deck: {
						quests: {
							$splice: [[questIndex, 1]],
						},
					},
				},
			})
		},
		addClassQuestion: ({
			params: { simulationId, classQuestion },
			selectors,
			getState,
		}: TileParams<{
			simulationId: string
			classQuestion: ClassQuestion
		}>) => {
			const simulations = selectors.simulations.store(getState())
			return update(simulations, {
				[simulationId]: {
					deck: {
						classQuestions: {
							$push: [classQuestion],
						},
					},
				},
			})
		},
		editClassQuestion: ({
			params: { simulationId, classQuestion },
			selectors,
			getState,
		}: TileParams<{
			simulationId: string
			classQuestion: ClassQuestion
		}>) => {
			const simulations = selectors.simulations.store(getState())

			if (!classQuestion._id) {
				return simulations
			}

			const simulation = simulations[simulationId]
			if (!simulation) {
				throw new Error(`Simulation with id ${simulationId} not found`)
			}

			const classQuestions = simulation.deck.classQuestions

			if (!classQuestions) {
				return simulations
			}

			const classQuestionIndex = classQuestions.findIndex(
				(question) => question._id === classQuestion._id
			)

			if (classQuestionIndex === -1) {
				return simulations
			}

			return update(simulations, {
				[simulationId]: {
					deck: {
						classQuestions: {
							$splice: [[classQuestionIndex, 1, classQuestion]],
						},
					},
				},
			})
		},
		deleteClassQuestion: ({
			params: { simulationId, classQuestionId },
			selectors,
			getState,
		}: TileParams<{
			simulationId: string
			classQuestionId: string
		}>) => {
			const simulations = selectors.simulations.store(getState())
			const simulation = simulations[simulationId]
			if (!simulation) {
				throw new Error(`Simulation with id ${simulationId} not found`)
			}
			const classQuestions = simulation.deck.classQuestions

			if (!classQuestions) {
				return simulations
			}

			const classQuestionIndex = classQuestions.findIndex(
				(classQuestion) => classQuestion._id === classQuestionId
			)

			if (classQuestionIndex === -1) {
				return simulations
			}

			return update(simulations, {
				[simulationId]: {
					deck: {
						classQuestions: {
							$splice: [[classQuestionIndex, 1]],
						},
					},
				},
			})
		},
	},
})
const get = createTile({
	type: ['simulations', 'get'],
	fn: async ({ params: id, actions, dispatch }: TileParams<string>) => {
		const res = await axios.get(`${URL}/${id}`).catch(onError)
		if (!res) return
		dispatch(actions.maps.store.addMany(res.data.maps))
		res.data.mapIds = res.data.maps.map((map: FullMap) => map._id)
		delete res.data.maps
		dispatch(actions.simulations.store.add(res.data))
	},
	nesting: (id) => [id],
})
const getAll = createTile({
	type: ['simulations', 'getAll'],
	fn: async ({ actions, dispatch }: TileParams<unknown>) => {
		const res = await axios.get(URL).catch(onError)
		if (!res) return
		const allMaps: FullMap[] = []
		const allSimulations: Simulation[] = []

		res.data.forEach((simulation: Simulation & { maps: FullMap[] }) => {
			allMaps.push(...simulation.maps)
			simulation.mapIds = simulation.maps.map((map) => map._id)
			// eslint-disable-next-line no-unused-vars
			const { maps, ...simulationWithoutMaps } = simulation
			allSimulations.push(simulationWithoutMaps)
		})
		dispatch(actions.simulations.store.addMany(allSimulations))
		dispatch(actions.maps.store.addMany(allMaps))
	},
})
const create = createTile({
	type: ['simulations', 'create'],
	fn: async ({ actions, dispatch, params }: TileParams<unknown>) => {
		const result = await axios.post(URL, params).catch(onError)

		if (!result) {
			return
		}

		const simulation = result.data
		dispatch(actions.maps.store.addMany(simulation.maps))
		simulation.mapIds = simulation.maps.map((map: FullMap) => map._id)
		delete simulation.maps
		dispatch(actions.simulations.store.add(simulation))
		return result.data
	},
})
const putQuestions = createTile({
	type: ['simulations', 'putQuestions'],
	fn: ({
		params,
		actions,
		dispatch,
		getState,
		selectors,
	}: TileParams<{ id: string; questions: ClientQuestion[]; queryClient: QueryClient }>) => {
		const { id, questions, queryClient } = params
		const formData = new FormData()
		formData.append('questions', JSON.stringify(questions))

		try {
			encodeMediaFiles(questions, formData)
		} catch (err) {
			console.log(err)
		}

		return axios
			.patch(`${URL}/${id}/questions`, formData)
			.then((response) => {
				const savedSimulation = response.data
				const didUpdateAutomatedSimulation = updateAutomatedSimulationInReactQuery(
					id,
					{
						questions: savedSimulation.questions,
					},
					queryClient
				)

				if (!didUpdateAutomatedSimulation) {
					dispatch(
						actions.simulations.store.updateQuestions({
							id: response.data._id,
							questions: response.data.questions,
						})
					)
				}

				toast.success('Success! Saved Questions.')
			})
			.catch(onError)
	},
	nesting: ({ id }) => [id],
})
const addMap = createTile({
	type: ['simulations', 'addMap'],
	fn: async ({
		params: { id, mapId },
		actions,
		selectors,
		dispatch,
		getState,
	}: TileParams<{ id: string; mapId: string }>) => {
		const simulation = selectors.simulations.store(getState())[id]
		if (!simulation) {
			throw new Error(`Simulation with id ${id} not found`)
		}
		const mapIds = simulation.mapIds
		const payload = {
			mapIds: update(mapIds, {
				$push: [mapId],
			}),
		}
		dispatch(
			actions.simulations.store.addMap({
				id,
				mapId,
			})
		)
		await axios.patch(`${URL}/${id}`, payload).catch(onError)
	},
	nesting: ({ id }) => [id],
})
const deleteMap = createTile({
	type: ['simulations', 'deleteMap'],
	fn: async ({
		params: { id, mapId },
		actions,
		selectors,
		dispatch,
		getState,
	}: TileParams<{ id: string; mapId: string }>) => {
		const simulation = selectors.simulations.store(getState())[id]
		if (!simulation) {
			throw new Error(`Simulation with id ${id} not found`)
		}
		const mapIds = simulation.mapIds
		const payload = {
			mapIds: update(mapIds, {
				$splice: [[mapIds.indexOf(mapId), 1]],
			}),
		}
		dispatch(
			actions.simulations.store.deleteMap({
				id,
				mapId,
			})
		)
		await axios.patch(`${URL}/${id}`, payload).catch(onError)
	},
	nesting: ({ id }) => [id],
})
const putMeta = createTile({
	type: ['simulations', 'putMeta'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{
		id: string
		imageUrl: string | null | undefined
		imageThumbnailUrl: string | null | undefined
	}>) => {
		const { id, ...payload } = params
		dispatch(actions.simulations.store.updateMeta(params))
		await axios.patch(`${URL}/${id}`, payload).catch(onError)
	},
	nesting: ({ id }) => [id],
})
const putCard = createTile({
	type: ['simulations', 'putCard'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{ simulationId: string; cardId: string }>) => {
		const { simulationId, cardId, ...payload } = params
		dispatch(actions.simulations.store.updateCardMeta(params))
		const response = await axios
			.patch(`${URL}/${simulationId}/cards/${cardId}`, payload)
			.catch(onError)

		if (
			response &&
			response.data &&
			response.data.launchpadGuides &&
			response.data.expositions &&
			response.data.effects
		) {
			const updatedParams = {
				...params,
				launchpadGuides: response.data.launchpadGuides,
				expositions: response.data.expositions,
				effects: response.data.effects,
			}
			dispatch(actions.simulations.store.updateCardMeta(updatedParams))
		}
	},
	nesting: ({ simulationId }) => [simulationId],
})
const addQuest = createTile({
	type: ['simulations', 'addQuest'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{ simulationId: string; locationIndex: number }>) => {
		const { simulationId, locationIndex } = params
		await axios
			.patch(`${URL}/${simulationId}/quests`, {
				locationIndex: locationIndex,
			})
			.then(() => {
				dispatch(actions.simulations.store.addQuest(params))
			})
			.catch(onError)
	},
	nesting: ({ id }) => [id],
})
const deleteQuest = createTile({
	type: ['simulations', 'deleteQuest'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{ simulationId: string; questIndex: number }>) => {
		const { simulationId, questIndex } = params
		axios
			.delete(`${URL}/${simulationId}/quests/${questIndex}`)
			.then(() => {
				dispatch(actions.simulations.store.deleteQuest(params))
			})
			.catch(onError)
	},
})
const addClassQuestion = createTile({
	type: ['simulations', 'addClassQuestion'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{
		simulationId: string
		classQuestion: ClassQuestionWithoutId
	}>) => {
		const { simulationId, classQuestion } = params
		axios
			.post(`${URL}/${simulationId}/classQuestions`, {
				classQuestion,
			})
			.then((result) => {
				dispatch(
					actions.simulations.store.addClassQuestion({
						simulationId,
						classQuestion: { ...classQuestion, _id: result.data.id },
					})
				)
			})
			.catch(onError)
	},
})
const editClassQuestion = createTile({
	type: ['simulations', 'editClassQuestion'],
	fn: async ({
		params: { simulationId, classQuestion },
		actions,
		dispatch,
	}: TileParams<{
		simulationId: string
		classQuestion: ClassQuestion
	}>) => {
		if (!classQuestion._id) {
			toast.warn('Class Question does not have an id. Please try again')
			return
		}

		axios
			.patch(`${URL}/${simulationId}/classQuestions/${classQuestion._id}`, {
				classQuestion,
			})
			.then(() =>
				dispatch(
					actions.simulations.store.editClassQuestion({
						simulationId,
						classQuestion,
					})
				)
			)
			.catch(onError)
	},
})
const deleteClassQuestion = createTile({
	type: ['simulations', 'deleteClassQuestion'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{
		simulationId: string
		classQuestionId: string
	}>) => {
		const { simulationId, classQuestionId } = params
		axios.delete(`${URL}/${simulationId}/classQuestions/${classQuestionId}`).then((result) => {
			dispatch(actions.simulations.store.deleteClassQuestion(params))
		})
	},
})
const addCard = createTile({
	type: ['simulations', 'addCard'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{ simulationId: string; cardIndex: number }>) => {
		const { simulationId, cardIndex } = params
		const result = await axios
			.post(`${URL}/${simulationId}/cards`, {
				index: cardIndex,
			})
			.catch(onError)

		if (result) {
			dispatch(
				actions.simulations.store.addCard({
					simulationId,
					cardIndex,
					card: result.data,
				})
			)
		}
	},
	nesting: ({ simulationId }) => [simulationId],
})
const deleteCard = createTile({
	type: ['simulations', 'deleteCard'],
	fn: async ({
		params: { simulationId, cardId },
		actions,
		dispatch,
	}: TileParams<{ simulationId: string; cardId: string }>) => {
		axios
			.delete(`${URL}/${simulationId}/cards/${cardId}`)
			.then(() => {
				dispatch(
					actions.simulations.store.deleteCard({
						simulationId,
						cardId,
					})
				)
			})
			.catch(onError)
	},
	nesting: ({ simulationId }) => [simulationId],
})
const putImage = createTile({
	type: ['simulations', 'putImage'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{
		simulationId: string
		image: Blob
		queryClient: QueryClient
		updateImageSettings: ImageUploadSettings
	}>) => {
		const { simulationId, image, queryClient, updateImageSettings } = params
		const data = new FormData()
		data.append('image', image)
		data.append('updateImageSettings', JSON.stringify(updateImageSettings))
		const res = await axios.patch(`${URL}/${simulationId}/image`, data).catch(onError)

		if (!res) {
			return
		}

		const { imageUrl, imageThumbnailUrl } = res.data
		const didUpdateAutomatedSimulation = updateAutomatedSimulationInReactQuery(
			simulationId,
			{
				imageUrl,
				imageThumbnailUrl,
			},
			queryClient
		)

		if (!didUpdateAutomatedSimulation) {
			dispatch(
				actions.simulations.store.updateMeta({
					id: simulationId,
					imageUrl,
					imageThumbnailUrl,
				})
			)
		}
	},
	nesting: ({ simulationId }) => [simulationId],
})
const deleteImage = createTile({
	type: ['simulations', 'deleteImage'],
	fn: async ({
		params,
		actions,
		dispatch,
		getState,
		selectors,
	}: TileParams<{
		simulationId: string
		queryClient: QueryClient
		updateImageSettings: ImageUploadSettings
	}>) => {
		const { simulationId, queryClient, updateImageSettings } = params
		let updateValues = {}
		if (updateImageSettings.thumbnail) updateValues = { imageThumbnailUrl: null }
		if (updateImageSettings.hero) updateValues = { ...updateValues, imageUrl: null }

		const didUpdateAutomatedSimulation = updateAutomatedSimulationInReactQuery(
			simulationId,
			{ ...updateValues },
			queryClient
		)

		if (!didUpdateAutomatedSimulation) {
			dispatch(
				actions.simulations.store.updateMeta({
					id: simulationId,
					imageUrl: null,
					imageThumbnailUrl: null,
				})
			)
		}

		await axios
			.delete(`${URL}/${simulationId}/image`, { data: { updateImageSettings } })
			.catch(onError)
	},
	nesting: ({ simulationId }) => [simulationId],
})
const moveCard = createTile({
	type: ['simulations', 'moveCard'],
	fn: async ({
		params,
		actions,
		dispatch,
	}: TileParams<{ simulationId: string; cardId: string }>) => {
		const { simulationId, cardId, ...payload } = params
		dispatch(actions.simulations.store.moveCard(params))
		await axios.patch(`${URL}/${simulationId}/cards/${cardId}/move`, payload).catch(onError)
	},
	nesting: ({ simulationId }) => [simulationId],
})
const tiles = [
	store,
	get,
	getAll,
	create,
	putQuestions,
	addMap,
	deleteMap,
	putMeta,
	addCard,
	deleteCard,
	putCard,
	putImage,
	deleteImage,
	moveCard,
	addQuest,
	deleteQuest,
	addClassQuestion,
	editClassQuestion,
	deleteClassQuestion,
]
export default tiles
