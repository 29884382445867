import React, { ReactNode, useState } from 'react'
import { reduxForm, Field, FieldArray, InjectedFormProps, FormSubmitHandler } from 'redux-form'
import { FormGroup, Label, FormText, Button, Row } from 'reactstrap'
import { startCase } from 'lodash'
import styled, { keyframes } from 'styled-components'
import CategorySelect from './SimulationCategories'
import MissionGuide from './MissionGuide/Form'
import GroupPermissionForm from './SimulationGroups'
import { Modal as AssetModal } from '../../common/AssetManager'
import { GradeSelectReduxForm } from '../../common/GradeSelect'
import SimulationImageSelection from './SimulationImage'
import TextList from '../../common/TextList'
import { FieldsStandardList } from '../../common/StandardList'
import MarkdownField from '../../common/MarkdownField'
import { AUTOMATED_SIMULATION_STATUSES } from '../automatedSimulations/helpers/constants'
import type { Simulation } from '../../types/Simulation'
import type { AutomatedSimulation } from '../../types/AutomatedSimulation'
import './SimulationDetails.css'
import { Edit3DScience } from './3dScience'
import { LEADERBOARD_STATUS } from '@mission.io/mission-toolkit/constants'
import { WalkthroughVideoSelectReduxForm } from '../../common/WalkthroughVideoSelect'

const checkpointsList = TextList('checkpoints')
type OwnProps = {
	simulation: Simulation | AutomatedSimulation
	onSubmit?: FormSubmitHandler<Simulation | AutomatedSimulation, OwnProps, string>
}
type ReduxFormsProps = InjectedFormProps<Simulation | AutomatedSimulation, OwnProps>
type Props = OwnProps & ReduxFormsProps

const SimulationDetails = ({
	simulation,
	change,
	initialValues,
	onSubmit,
	handleSubmit,
	dirty,
}: Props) => {
	const [videoChooserOpen, setVideoChooserOpen] = useState(false)
	const [videoChooserField, setVideoChooserField] = useState<
		'walkthroughVideoUrl' | 'briefingVideoUrl'
	>('walkthroughVideoUrl')

	const isValidFormName = (formName: string) => {
		return initialValues.hasOwnProperty(formName)
	}

	return (
		<Wrapper id="SimulationDetails">
			{onSubmit && (
				<div className="save-button-container">
					<Button onClick={handleSubmit} color="primary" disabled={!dirty}>
						Save
					</Button>
				</div>
			)}
			<Row>
				<OptionalField
					label="Mission Tag"
					formName="tag"
					initialValues={initialValues}
					className="col-sm-2"
				/>
				<OptionalField
					id="simulationName"
					formName="name"
					initialValues={initialValues}
					className="col-sm">
					{'originalName' in simulation && (
						<FormText>Originally was “{simulation.originalName}”.</FormText>
					)}
				</OptionalField>
				<OptionalField formName="subjectMatter" initialValues={initialValues} className="col-sm" />
			</Row>
			<div css="display: flex; justify-content: space-between; gap: 16px;">
				<SimulationImageSelection
					simulationId={simulation._id}
					imageUrl={simulation.imageUrl}
					imageThumbnailUrl={simulation.imageThumbnailUrl}
				/>
				<OptionalField
					formName="grades"
					component={GradeSelectReduxForm}
					label="Grades"
					initialValues={initialValues}
					multi>
					<FormText>
						Multiple grades can be selected by holding ‘Ctrl’ (PC) or ‘Cmd’ (Mac) and clicking.
					</FormText>
				</OptionalField>

				<OptionalField
					formName="status"
					component="select"
					label="Status"
					initialValues={initialValues}
					fieldChildren={
						<>
							<option />
							{Object.keys(AUTOMATED_SIMULATION_STATUSES).map((status) => {
								const { value, text } =
									AUTOMATED_SIMULATION_STATUSES[
										status as keyof typeof AUTOMATED_SIMULATION_STATUSES
									]
								return (
									<option value={value} key={status}>
										{text}
									</option>
								)
							})}
						</>
					}>
					<FormText>Whether and how the mission should be displayed in Mission Control</FormText>
				</OptionalField>
				<OptionalField
					formName="public"
					type="checkbox"
					className="check-reset"
					fieldClassName="form-check-input"
					labelClassName="form-check-label"
					label="Show in Dashboard"
					displayLabelAfter
					initialValues={initialValues}>
					<FormText>Choose if the simulation is public or not.</FormText>
				</OptionalField>

				<OptionalField
					formName="isQuest"
					type="checkbox"
					className="check-reset"
					fieldClassName="form-check-input"
					labelClassName="form-check-label"
					label="Mark as Quest"
					displayLabelAfter
					initialValues={initialValues}>
					<FormText>
						Choose whether the simulation is a quest or not. If marked as a quest, it will not
						appear with other simulations on Dashboard.
					</FormText>
				</OptionalField>
			</div>
			<FlexRow>
				<GroupPermissionForm simulation={simulation} />
				{isValidFormName('categories') && <Field name="categories" component={CategorySelect} />}
			</FlexRow>
			<OptionalField formName="duration" type="number" initialValues={initialValues}>
				<FormText>Estimated time (in minutes) needed to complete this simulation.</FormText>
			</OptionalField>
			<OptionalField formName="summary" initialValues={initialValues} component={MarkdownField}>
				<FormText>A brief overview (usually 2-3 sentences) of the simulation.</FormText>
			</OptionalField>
			<OptionalField
				formName="narration"
				label="Storyline"
				initialValues={initialValues}
				component={MarkdownField}>
				<FormText>
					Full description of the simulation. Usually includes simulation objectives and briefings.
				</FormText>
			</OptionalField>
			<OptionalField formName="briefing" initialValues={initialValues} component={MarkdownField}>
				<FormText>Introductory information to tell students before beginning mission.</FormText>
			</OptionalField>
			{isValidFormName('briefingVideoUrl') && (
				<FormGroup>
					<AnnoyingLoudLabel>Briefing Video</AnnoyingLoudLabel>
					<FormText>Video that can be shown to students before flying.</FormText>

					<div className="d-flex align-items-baseline mb-2">
						<Button
							className="mr-2"
							color="primary"
							size="sm"
							onClick={() => {
								setVideoChooserOpen(true)
								setVideoChooserField('briefingVideoUrl')
							}}>
							Choose
						</Button>

						{simulation.briefingVideoUrl && (
							<Button
								className="mr-2"
								color="danger"
								size="sm"
								onClick={() => {
									change('briefingVideoUrl', null)
								}}>
								Clear
							</Button>
						)}

						<span>
							{simulation.briefingVideoUrl ? simulation.briefingVideoUrl : 'No video selected'}
						</span>
					</div>
				</FormGroup>
			)}

			{isValidFormName('walkthroughVideo') && 'walkthroughVideo' in simulation && (
				<OptionalField
					formName="walkthroughVideo"
					component={WalkthroughVideoSelectReduxForm}
					label="Walkthrough Video"
					initialValues={initialValues}>
					<FormText>
						A video for teachers that walks through the mission. If using a loom video, use the
						&apos;embed&apos; link!
					</FormText>
				</OptionalField>
			)}
			{isValidFormName('checkpoints') && (
				<FormGroup>
					<Label>Checkpoints</Label>
					<FormText>
						Key mission points that helps the teacher through the story. Commonly includes actions
						students make during the mission.
					</FormText>
					<FieldArray name="checkpoints" component={checkpointsList} />
				</FormGroup>
			)}
			{isValidFormName('standards') && (
				<FormGroup>
					<Label>Standards</Label>
					<FormText>Curriculum standards covered in this simulation.</FormText>
					<FieldArray name="standards" component={FieldsStandardList} />
				</FormGroup>
			)}
			{isValidFormName('science3D') && <Edit3DScience />}
			<OptionalField
				initialValues={initialValues}
				formName="crossCurricularConnections"
				label="Cross-Curricular Connections"
				component={MarkdownField}></OptionalField>
			<OptionalField initialValues={initialValues} formName="debriefing" component={MarkdownField}>
				<FormText>Questions teachers can ask after the mission.</FormText>
			</OptionalField>
			<OptionalField
				formName="maxQuestions"
				type="number"
				initialValues={initialValues}
				label="Max Questions">
				<FormText>Maximum number of questions allowed on the mission</FormText>
			</OptionalField>
			<OptionalField
				formName="generatesAnalytics"
				type="checkbox"
				fieldClassName="form-check-input"
				labelClassName="form-check-label"
				initialValues={initialValues}
				displayLabelAfter={true}
				label="Generates Analytics">
				<FormText>Generate Analytics For Missions</FormText>
			</OptionalField>
			<FormGroup>
				<OptionalField
					formName="leaderboardStatus"
					component="select"
					label="Leaderboard Status"
					initialValues={initialValues}
					fieldChildren={
						<>
							{Object.keys(LEADERBOARD_STATUS).map((status) => {
								return (
									<option value={status} key={status}>
										{status}
									</option>
								)
							})}
						</>
					}>
					<FormText>
						Describes if the leaderboard for this simulation should be available to the users.
					</FormText>
				</OptionalField>
			</FormGroup>
			{'missionGuide' in initialValues ? <MissionGuide simulation={simulation} /> : null}
			<AssetModal
				collection="videos"
				isOpen={videoChooserOpen}
				onClose={() => setVideoChooserOpen(false)}
				onFileClick={(file) => {
					change(videoChooserField, file.url)
					setVideoChooserOpen(false)
				}}
			/>
		</Wrapper>
	)
}

export default reduxForm<Simulation | AutomatedSimulation, OwnProps>({ enableReinitialize: true })(
	SimulationDetails
)
/**
 * A component to display a form group for the field in redux form indicated by `formName`. The form group is conditionally rendered
 * based on the `initialValues` prop. Whatever is passed as `children` will be rendered just before the `Field` component. Besides this
 * functionality, other props are only for convenience in passing through to the underlying components. Any extra props besides those described
 * in the function definition will be passed through as props to the underlying `Field` component.
 */

function OptionalField({
	formName,
	initialValues,
	id,
	children,
	className,
	label,
	displayLabelAfter,
	labelClassName,
	fieldClassName,
	component,
	fieldChildren,
	...fieldProps
}: {
	formName: string
	initialValues: Record<string, unknown>
	id?: string
	children?: ReactNode
	className?: string
	label?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component?: React.ComponentType<any> | 'select'
	displayLabelAfter?: boolean
	labelClassName?: string
	fieldClassName?: string
	fieldChildren?: JSX.Element
	multi?: boolean
	type?: string
}) {
	if (!initialValues.hasOwnProperty(formName)) {
		return null
	}

	id = id ?? formName
	label = label ?? startCase(formName)
	const renderedLabel = (
		<Label for={id} className={labelClassName}>
			{label}
		</Label>
	)
	return (
		<FormGroup className={className}>
			{!displayLabelAfter && renderedLabel}
			{children}
			<Field
				{...fieldProps}
				id={id}
				name={formName}
				component={component || 'input'}
				className={fieldClassName ?? 'form-control'}>
				{fieldChildren}
			</Field>
			{displayLabelAfter && renderedLabel}
		</FormGroup>
	)
}

const pulseSize = keyframes`
  from {
    font-size: 1rem;
	color: red;
  }

  to {
    font-size: 2rem;
	color: yellow;
  }
`
const AnnoyingLoudLabel = styled(Label)`
	animation: ${pulseSize} 0.5s linear infinite alternate;
	height: 40px;
	box-shadow: 0px 0px 10px blue;
	border-radius: 10px;
	display: inline-flex;
	align-items: center;
	width: 200px;
`
const FlexRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;

	> * {
		flex: 1 0 auto;
	}
`
const Wrapper = styled.div`
	.form-text {
		display: block;
		margin-top: 0;
	}
`
