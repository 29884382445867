import type { BasicAutomatedSimulation } from '../../types/AutomatedSimulation'
import { generateAndUploadMissionGuides } from './MissionGuide'
import { fetchAutomatedSimulation } from './queries'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	Button,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap'
import styled from 'styled-components'
import { mapEntries } from '../../helpers/functions'
import { max, min, sortBy } from 'lodash'
import { useLoadPdfDependencies } from '@mission.io/mission-guide'
import Spinner from './ActionBank/common/Spinner'

/**
 * Function to create and upload mission guides for simulations
 *
 * @param {BasicAutomatedSimulation[]} simulations - the list of simulations for which guides are being uploaded
 */
const uploadMissionGuides = async ({
	simulations,
}: {
	simulations: BasicAutomatedSimulation[]
}) => {
	const failedFetches: BasicAutomatedSimulation[] = []
	const failedUploads: Array<BasicAutomatedSimulation> = []
	const toastId = toast.info(`Beginning upload of ${simulations.length} Mission Guides.`, {
		autoClose: false,
		closeButton: false,
		closeOnClick: false,
		position: 'bottom-right',
	})

	for (
		let currentSimulationIndex = 0;
		currentSimulationIndex < simulations.length;
		currentSimulationIndex++
	) {
		const currentSimulation = simulations[currentSimulationIndex]
		const currentSimulationJsx = (
			<b>
				{/*
     // @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess */}
				{currentSimulation.name} ({currentSimulationIndex + 1}/{simulations.length})
			</b>
		)

		toast.update(toastId, {
			render: currentSimulationJsx,
		})
		// @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		const simulation = await fetchAutomatedSimulation(currentSimulation._id).catch(() => {
			// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			failedFetches.push(currentSimulation)
		})

		if (!simulation) {
			continue
		}

		try {
			await generateAndUploadMissionGuides(simulation, {
				onUpdate(update) {
					if (update.status === 'error') {
						// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
						failedUploads.push(currentSimulation)
					}
					if (update.status === 'generating' || update.status === 'uploading') {
						const singlePdf = update.currentPdfs.first === update.currentPdfs.last

						// text in the format "Mission Guides 1-5 of 7 (default, UT, TX, CA, NY)..."
						const missionGuideNumbers = `Guide${singlePdf ? '' : 's'} ${update.currentPdfs.first}${
							!singlePdf ? `-${update.currentPdfs.last}` : ''
						} of ${update.totalPdfs}`
						toast.update(toastId, {
							render: (
								<>
									{currentSimulationJsx}
									<div css="margin-top: 8px;">
										{update.status === 'generating' ? 'Generating' : 'Uploading'}{' '}
										{missionGuideNumbers} ({update.currentPdfs.variants.join(', ')})
									</div>
								</>
							),
						})
					}
				},
			})
		} catch (error) {
			// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
			failedUploads.push(currentSimulation)
		}
	}

	toast.update(toastId, {
		render: (
			<div css="max-height: 70vh; overflow-y: scroll;">
				{failedFetches.length || failedUploads.length ? (
					<h5>Finished uploading guides with errors</h5>
				) : (
					`Guides for ${simulations.length} simulations uploaded successfully!`
				)}

				{failedFetches.length > 0 && (
					<>
						<h6>Failed to fetch ({failedFetches.length}):</h6>
						<ul>
							{failedFetches.map(({ name, _id }) => (
								<li key={_id}>
									<WhiteLink to={`/${_id}`}>{name}</WhiteLink>
								</li>
							))}
						</ul>
					</>
				)}
				{failedUploads.length > 0 && (
					<>
						<h6>Failed to generate or upload guide ({failedUploads.length}):</h6>
						<ul>
							{failedUploads.map(({ name, _id }) => (
								<li key={_id}>
									<WhiteLink to={`/${_id}`}>{name}</WhiteLink>
								</li>
							))}
						</ul>
					</>
				)}
			</div>
		),
		type: failedFetches.length || failedUploads.length ? 'error' : 'success',
		closeButton: true,
	})
}

/**
 * A button that when press will go through all simulations and generate and upload mission guides for each simulation.
 */
export function UploadAllMissionGuidesButton({
	simulations,
}: {
	simulations: BasicAutomatedSimulation[]
}): JSX.Element {
	const [showUploadOptionsOverlay, setShowUploadOptionsOverlay] = React.useState(false)
	return (
		<>
			<Button onClick={() => setShowUploadOptionsOverlay((prev) => !prev)}>
				Upload Mission Guides
			</Button>
			<UploadMissionGuidesOverlay
				isOpen={showUploadOptionsOverlay}
				onClose={() => {
					setShowUploadOptionsOverlay(false)
				}}
				simulations={simulations}
			/>
		</>
	)
}

const DROPDOWN_OPTIONS = {
	UPLOAD_ALL: 'Upload All',
	CUSTOM_RANGE: 'Custom Range',
}

/**
 * Modal to provide custom range options for uploading mission guides
 *
 * @param {boolean} isOpen - if the modal is open
 * @param {() => void} onClose - function to be called on modal close
 * @param {BasicAutomatedSimulation[]} simulations - the list of simulations for which guides should be uploaded
 * @returns
 */
const UploadMissionGuidesOverlay = ({
	isOpen,
	onClose,
	simulations,
}: {
	isOpen: boolean
	onClose: () => void
	simulations: BasicAutomatedSimulation[]
}) => {
	const finalSimulationIndex = simulations.length - 1
	const [dropdownOpen, setDropdownOpen] = React.useState(false)
	const [options, setOptions] = React.useState({
		rangeStart: 0,
		rangeEnd: finalSimulationIndex,
		dropdownSelection: DROPDOWN_OPTIONS.UPLOAD_ALL,
	})
	const [validationError, setValidationError] = React.useState<string | null>(null)
	const sortedSimulations = sortBy(simulations, 'name')
	const { loaded: dependenciesLoaded, errors: pdfDependencyLoadErrors } = useLoadPdfDependencies()

	return (
		<Modal style={{ minWidth: '50%' }} isOpen={isOpen}>
			<ModalHeader toggle={onClose}>Upload Mission Guides</ModalHeader>
			<ModalBody>
				<Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen((prev) => !prev)} sm={3}>
					<DropdownToggle caret>{options.dropdownSelection}</DropdownToggle>
					<DropdownMenu>
						{mapEntries(DROPDOWN_OPTIONS, (currentOption, currentKey) => {
							return (
								<DropdownItem
									key={currentKey}
									onClick={() =>
										setOptions((prev) => ({
											...prev,
											dropdownSelection: currentOption,
										}))
									}>
									{currentOption}
								</DropdownItem>
							)
						})}
					</DropdownMenu>
				</Dropdown>
				{options.dropdownSelection === DROPDOWN_OPTIONS.CUSTOM_RANGE && (
					<div className="my-3">
						(Simulations sorted alphabetically)
						<div className="flex flex-row items-center mt-3">
							<div className="w-1/2">
								<div className="flex flex-row items-center">
									<div>Range Start:</div>
									<Input
										className="w-auto mx-3"
										type="number"
										max={min([finalSimulationIndex + 1, options.rangeEnd])}
										min={1}
										value={options.rangeStart + 1}
										onChange={(e) => {
											setOptions((prev) => ({ ...prev, rangeStart: e.target.valueAsNumber - 1 }))
										}}
									/>
								</div>
								<div>Starting Simulation: {sortedSimulations[options.rangeStart]?.name}</div>
							</div>
							<div className="w-1/2">
								<div className="flex flex-row items-center">
									<div>Range End:</div>
									<Input
										className="ml-3 w-auto"
										type="number"
										max={finalSimulationIndex + 1}
										min={max([1, options.rangeStart + 1])}
										value={options.rangeEnd + 1}
										onChange={(e) => {
											setOptions((prev) => ({ ...prev, rangeEnd: e.target.valueAsNumber - 1 }))
										}}
									/>
								</div>
								<div>Ending Simulation: {sortedSimulations[options.rangeEnd]?.name}</div>
							</div>
						</div>
					</div>
				)}
				<ModalFooter className="mt-4">
					{validationError ||
						(pdfDependencyLoadErrors.some((e) => !!e) && (
							<div className="text-error">
								{validationError ||
									pdfDependencyLoadErrors
										.filter((e) => !!e)
										.map((e) => e.message)
										.join(', ')}
							</div>
						))}
					{!dependenciesLoaded ? (
						<div className="flex flex-row text-sm gap-2 items-center">
							<Spinner headerClassName="m-0" spinnerClassName="[&&]:w-4 [&&]:h-4" />
							<span className="italic">Loading Dependencies</span>
						</div>
					) : null}
					<Button
						onClick={() => {
							setValidationError('')
							if (options.dropdownSelection === DROPDOWN_OPTIONS.UPLOAD_ALL) {
								uploadMissionGuides({ simulations: sortedSimulations })
								return
							}
							const { valid, errorMessage } = validateCustomOptions({
								options,
								simulations: sortedSimulations,
							})
							if (valid) {
								uploadMissionGuides({
									simulations: sortedSimulations.slice(options.rangeStart, options.rangeEnd + 1),
								})
								return
							}
							if (errorMessage) setValidationError(errorMessage)
						}}
						disabled={pdfDependencyLoadErrors.some((e) => !!e) || !dependenciesLoaded}>
						Start Uploads
					</Button>
				</ModalFooter>
			</ModalBody>
		</Modal>
	)
}

/**
 * Validate upload mission guide options
 *
 * @param {{rangeStart: number, rangeEnd: number}} options - mission guide upload options
 * @param {BasicAutomatedSimulation[]} simulations - the list of simulations for which guides should be uploaded
 * @returns
 */
const validateCustomOptions = ({
	options,
	simulations,
}: {
	simulations: BasicAutomatedSimulation[]
	options: {
		rangeStart: number
		rangeEnd: number
	}
}) => {
	if (!isFinite(options.rangeStart) || !isFinite(options.rangeEnd))
		return { valid: false, errorMessage: 'Range start and end must both be numbers' }
	const { rangeStart, rangeEnd } = options
	if (rangeEnd < rangeStart)
		return { valid: false, errorMessage: 'Range end must be >= range start' }
	if (rangeEnd < 0 || rangeStart < 0)
		return { valid: false, errorMessage: 'Range start and end must be greater than 0' }
	if (rangeEnd > simulations.length - 1 || rangeStart > simulations.length - 1)
		return {
			valid: false,
			errorMessage: 'Range start and end must be less than simulations list length',
		}
	return { valid: true }
}

const WhiteLink = styled(Link)`
	color: white;
	&:hover {
		color: #e6e6e6;
	}
`
