import React from 'react'
import { Field } from 'redux-form'
import { WalkthroughVideo } from '../types/AutomatedSimulation'
import { Modal as AssetModal } from './AssetManager'
import { Button } from 'reactstrap'

/**
 * A wrapper of WalkthroughVideoSelect which allows it to be passed a `component` prop of a
 * redux-form Field.
 */
export function WalkthroughVideoSelectReduxForm({
	input: { value, onChange },
	...props
}: {
	input: {
		value: WalkthroughVideo
		onChange: (walkthroughVideo: WalkthroughVideo | null) => void
	}
}): JSX.Element {
	return <WalkthroughVideoSelect value={value} onChange={onChange} {...props} />
}

/**
 * A component that allows users to select a walkthrough video, either from Loom or a video file.
 * It provides a dropdown to select the type of video and an input field or button to specify the URL.
 * It also validates the URL based on the selected type.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.id] - The optional id for the select element.
 * @param {WalkthroughVideo | null | undefined} props.value - The current value of the walkthrough video.
 * @param {(walkthroughVideo: WalkthroughVideo | null) => void} props.onChange - The function to call when the value changes.
 * @returns {JSX.Element} The rendered component.
 */
export function WalkthroughVideoSelect({
	id,
	value,
	onChange,
	...props
}: {
	id?: string
	value: WalkthroughVideo | null | undefined
	onChange: (walkthroughVideo: WalkthroughVideo | null) => void
}): JSX.Element {
	const [videoChooserOpen, setVideoChooserOpen] = React.useState(false)
	const [error, setError] = React.useState<string | null>(null)

	React.useEffect(() => {
		if (value && !value.url) {
			setError('Walkthrough video URL is required')
		} else if (value && value.type === 'loom' && !value.url?.includes('/embed/')) {
			setError('Loom Walkthrough video URLs must include /embed/')
		} else {
			setError(null)
		}
	}, [value])
	return (
		<>
			<div className="d-flex flex-row align-items-baseline mb-2">
				<>
					<label htmlFor={id} className="mr-2">
						Type:
					</label>
					<select
						{...props}
						id={id}
						className="form-control max-w-[45%]"
						value={value?.type || ''}
						onChange={(e) => {
							if (e.target.value === '') {
								onChange(null)
							}
							onChange({ type: e.target.value as 'loom' | 'video', url: value?.url || '' })
						}}>
						<option value="">None</option>
						<option value="loom">Loom</option>
						<option value="video">Video</option>
					</select>
				</>

				{value && (
					<>
						<label htmlFor="walkthroughVideo.url" className="ml-2 mr-2">
							URL:
						</label>
						{value?.type && value?.type === 'loom' && (
							<Field
								name="walkthroughVideo.url"
								component="input"
								type="text"
								className="form-control"
								id="walkthroughVideo.url"
								placeholder="Walkthrough Video URL"
							/>
						)}
						{value?.type && value?.type === 'video' && (
							<>
								<Button
									className="mr-2"
									color="primary"
									size="sm"
									onClick={() => {
										setVideoChooserOpen(true)
									}}>
									Choose
								</Button>

								{value.url && (
									<Button
										className="mr-2"
										color="danger"
										size="sm"
										onClick={() => {
											onChange({ type: 'video', url: '' })
										}}>
										Clear
									</Button>
								)}

								<span>{value.url ?? 'No video selected'}</span>
							</>
						)}
					</>
				)}
			</div>
			{error && <div className="alert alert-danger">{error}</div>}
			{value && value.url && <VideoPreview url={value.url} type={value.type} />}
			<AssetModal
				collection="videos"
				isOpen={videoChooserOpen}
				onClose={() => {
					setVideoChooserOpen(false)
				}}
				onFileClick={(file) => {
					onChange({ type: 'video', url: file.url })
					setVideoChooserOpen(false)
				}}
			/>
		</>
	)
}

/**
 * VideoPreview component renders a video preview based on the provided URL and type.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.url - The URL of the video to be displayed.
 * @param {'loom' | 'video'} props.type - The type of the video, either 'loom' for Loom videos or 'video' for standard video files.
 *
 * @returns {JSX.Element} The JSX element for the video preview.
 */
const VideoPreview = ({ url, type }: { url: string; type: 'loom' | 'video' }) => {
	if (type === 'loom')
		return (
			<div>
				<iframe
					title={`walkthrough video`}
					src={url}
					frameBorder="0"
					// @ts-expect-error TS2322 AUTO SUPPRESSED ERROR FROM TS CONVERSION
					webkitallowfullscreen
					mozallowfullscreen
					allowFullScreen
				/>
			</div>
		)

	return <video src={url} controls className="max-w-xs" />
}
