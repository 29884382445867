import React, { Component, Fragment } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Link, RouteComponentProps } from 'react-router-dom'
import { Navbar, Nav, NavLink, Button, NavItem } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import GlobalStyles from './utils/globalStyles'
import 'react-toastify/dist/ReactToastify.css'
import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.css'
import ScrollToTop from './common/ScrollToTop'
import { SimulationList, Simulation } from './features/simulations'
import { CharacterBank } from './features/characters'
import Editor from './features/navigationMaps/Editor'
import PreviewNavigationMap from './features/navigationMaps/PreviewRoute'
import NavigationMapBank from './features/navigationMaps/NavigationMapBank'
import { Modal as AssetModal } from './common/AssetManager'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import LoginWrapper from './common/LoginWrapper'
import { StandardsPage } from './features/standards/StandardsPage'
import { Store } from 'redux'
import { ReduxStore } from './types/ReduxStore'
import Archive from './features/simulations/Archive'

const queryClient = new QueryClient()
export type User = Record<string, unknown>
type Props = {
	store: Store<ReduxStore>
}
type State = {
	user: User | null | undefined
	assetModalIsOpen: boolean
}

class App extends Component<Props, State> {
	state: State = {
		user: undefined,
		assetModalIsOpen: false,
	}
	toggleAssetModal: () => void = () => {
		this.setState((curState) => ({
			assetModalIsOpen: !curState.assetModalIsOpen,
		}))
	}

	render(): JSX.Element {
		const { store } = this.props
		return (
			// @ts-expect-error Provider props need to be updated to include `children`
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					{/* @ts-expect-error DndProvider props need to be updated to include `children` */}
					<DndProvider backend={HTML5Backend}>
						<LoginWrapper>
							<AssetModal
								collection="automatedSimulationMedia"
								isOpen={this.state.assetModalIsOpen}
								onClose={this.toggleAssetModal}
							/>
							<Router>
								<ScrollToTop>
									<Fragment>
										<ToastContainer />
										<GlobalStyles />
										<Navbar light color="light" className="shadow mb-3">
											<Link to="/" className="navbar-brand">
												Simulation Creator
											</Link>

											<Nav pills>
												<NavItem>
													<NavLink tag={Link} to="/navigationMapBank">
														Nav Bank
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink tag={Link} to="/characterBank">
														Characters
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink tag={Link} to="/standards">
														Standards
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink tag={Link} to="/archive">
														Archive
													</NavLink>
												</NavItem>
												<NavItem>
													<Button onClick={this.toggleAssetModal} outline>
														Add Media
													</Button>
												</NavItem>
											</Nav>
										</Navbar>
										<Switch>
											<Route
												path="/editor/entity"
												exact
												component={() => <Editor isEntityEditor={true} />}
											/>
											<Route
												path="/editor/entity/:id"
												exact
												component={({ match: { params } }: RouteComponentProps<{ id: string }>) => {
													return <Editor isEntityEditor={true} editing={params.id} />
												}}
											/>
											<Route
												path="/editor/world"
												exact
												component={() => <Editor isEntityEditor={false} />}
											/>
											<Route
												path="/editor/world/:id"
												exact
												component={({ match: { params } }: RouteComponentProps<{ id: string }>) => {
													return <Editor isEntityEditor={false} editing={params.id} />
												}}
											/>
											<Route
												path="/navigation/preview/:id"
												exact
												component={PreviewNavigationMap}
											/>
											<Route path="/navigationMapBank" exact component={NavigationMapBank} />
											<Route path="/archive" exact component={Archive} />
											<Route path="/characterBank" exact component={CharacterBank} />
											<Route path="/standards" exact component={StandardsPage} />
											<Route path="/:id" exact component={Simulation} />
											<Route path="/" exact component={SimulationList} />
										</Switch>
									</Fragment>
								</ScrollToTop>
							</Router>
						</LoginWrapper>
						<ReactQueryDevtools />
					</DndProvider>
				</QueryClientProvider>
			</Provider>
		)
	}
}

export default App
