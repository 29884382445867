import { ONE_MINUTE } from '../../../helpers/constants'
import { getDefaultDefenseTarget, getDefaultTractorBeamTarget } from '../actionDefinitions'
import type {
	CreativeCanvasIssue,
	CreativeCanvasIssueCriterion,
	CreativeCanvasIssueGradingOption,
	CreativeCanvasStationData,
	JrPlusActivateStationData,
	JrActivateStationData,
	JrPlusStationId,
	JrStationId,
	DefensePlusTarget,
	TractorBeamPlusTarget,
	NewObjectInfo,
} from '@mission.io/mission-toolkit/actions'
import {
	CREATIVE_CANVAS_STATION,
	JR_PLUS_STATION_IDS,
	SHARED_STATION_IDS,
} from '@mission.io/mission-toolkit/constants'
import { createTemporaryId } from '../../../helpers/functions'
const DEFAULT_POWER = 6
const DEFAULT_POWER_MAX = 5
const DEFAULT_POWER_MIN = 3
const DEFAULT_VALUE = 5
const DEFAULT_REPAIR_DURATION = 3 * ONE_MINUTE
const DEFAULT_REPAIR_HEALTH_PER_ROUND = 25
type OldObjectInfo = {
	mapId: string | null | undefined
	mapObjectId: string | null | undefined
	objectName: string | null | undefined
	objectDescription: string | null | undefined
}
type ReusableObjectInfo = NewObjectInfo & {
	media: {
		type: 'IMAGE'
		url: string
	} | null
}

/**
 * Gets all info from the given `target` that is reusable when switching station types
 */
function getReusableInfoForTarget(
	target: DefensePlusTarget<string> | TractorBeamPlusTarget<string>
): ReusableObjectInfo {
	return {
		media: target.media,
		...(target.mapObject
			? {
					mapObject: target.mapObject,
			  }
			: {
					actionObjectInfo: target.actionObjectInfo || {
						objectName: '',
						objectDescription: '',
					},
			  }),
	}
}

/**
 * Gets object info from `stationData` in the format used by the newer stations
 */
function getNewObjectInfo(stationData: JrPlusActivateStationData<string>): ReusableObjectInfo[] {
	if (
		stationData.stationId === JR_PLUS_STATION_IDS.DEFENSE_PLUS ||
		stationData.stationId === JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS
	) {
		return stationData.targets.map((target) => getReusableInfoForTarget(target))
	}

	return 'mapId' in stationData &&
		!!stationData.mapId &&
		'mapObjectId' in stationData &&
		!!stationData.mapObjectId
		? [
				{
					media: null,
					mapObject: {
						mapId: stationData.mapId,
						mapObjectId: stationData.mapObjectId,
					},
				},
		  ]
		: [
				{
					media: null,

					actionObjectInfo: {
						objectName: ('objectName' in stationData && stationData.objectName) || '',
						objectDescription:
							('objectDescription' in stationData && stationData.objectDescription) || '',
					},
				},
		  ]
}

/**
 * Gets object info from `stationData` in the format required by older stations
 */
function getOldObjectInfo(stationData: JrPlusActivateStationData<string>) {
	if (
		stationData.stationId === JR_PLUS_STATION_IDS.DEFENSE_PLUS ||
		stationData.stationId === JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS
	) {
		const oldObjectInfo: OldObjectInfo = {
			objectName: null,
			objectDescription: null,
			mapId: null,
			mapObjectId: null,
		}
		const target = stationData.targets[0]

		if (!target) {
			return oldObjectInfo
		}

		const targetObjectInfo = getReusableInfoForTarget(target)
		return Object.assign(
			oldObjectInfo,
			targetObjectInfo.mapObject || targetObjectInfo.actionObjectInfo
		)
	}

	return {
		mapId: ('mapId' in stationData && stationData.mapId) || null,
		mapObjectId: ('mapObjectId' in stationData && stationData.mapObjectId) || null,
		objectName: ('objectName' in stationData && stationData.objectName) || null,
		objectDescription:
			('objectDescription' in stationData && stationData.objectDescription) || null,
	}
}

/**
 * Gets a default issue for the creative canvas station
 */
export function getNewCreativeCanvasIssue(): CreativeCanvasIssue<string> {
	return {
		_id: createTemporaryId(),
		prompt: '',
		rubric: {
			onSuccess: [],
			onFail: [],
			requiredScore: 2,
			criteria: [
				getNewCreativeCanvasIssueCriterion(),
				getNewCreativeCanvasIssueCriterion(),
				getNewCreativeCanvasIssueCriterion(),
			],
		},
		data: [],
		backgroundMedia: null,
		designMedia: [],
		canvasTools: [],
		teacherTips: [],
	}
}

/**
 * Gets a default criterion for a creative canvas station issue
 */
export function getNewCreativeCanvasIssueCriterion(): CreativeCanvasIssueCriterion<string> {
	return {
		_id: createTemporaryId(),
		text: '',
		displayType: CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.VISIBLE,
		gradingOptions: [
			{
				_id: createTemporaryId(),
				text: 'Yes',
				score: 1,
				correct: true,
			},
			{
				_id: createTemporaryId(),
				text: 'No',
				score: 0,
				correct: false,
			},
		],
		required: false,
	}
}

/**
 * Gets a default custom grading option for a creative canvas station issue criterion
 */
export function getNewCreativeCanvasGradingOption(): CreativeCanvasIssueGradingOption<string> {
	return {
		_id: createTemporaryId(),
		text: '',
		score: 1,
		correct: true,
	}
}

const DEFAULT_CANVAS_TOOLS = [
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.DRAW,
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.TEXT,
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.IMAGE,
	CREATIVE_CANVAS_STATION.CANVAS_TOOLS.SHAPE,
]

/**
 * Gets a new station data for a creative canvas station
 */
export function getCreativeCanvasStationData(): CreativeCanvasStationData<string> & {
	variant: typeof CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE
} {
	return {
		stationId: SHARED_STATION_IDS.CREATIVE_CANVAS,
		backgroundMedia: null,
		data: [],
		designMedia: [],
		variant: CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE,
		issue: getNewCreativeCanvasIssue(),
		canvasTools: DEFAULT_CANVAS_TOOLS,
	}
}
export function handleJrPlusStationChange(
	stationData: JrPlusActivateStationData<string>,
	newStationId: JrPlusStationId
): JrPlusActivateStationData<string> {
	const oldProperty = getChangingProperty(stationData.stationId)
	// @ts-expect-error We want to use `oldProperty` to index `stationData`
	const oldValue: number = oldProperty in stationData ? stationData[oldProperty] : DEFAULT_VALUE

	switch (newStationId) {
		case 'TRANSPORTER':
			return {
				stationId: newStationId,
				connectionsPerStudent: oldValue,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				...getOldObjectInfo(stationData),
			}

		case 'POWER':
			return {
				stationId: newStationId,
				min: DEFAULT_POWER_MIN,
				max: DEFAULT_POWER_MAX,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				...getOldObjectInfo(stationData),
			}

		case JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS:
			return {
				stationId: 'TRACTOR_BEAM_PLUS',
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				targets: getNewObjectInfo(stationData).map((objectInfo) => ({
					...getDefaultTractorBeamTarget(),
					...objectInfo,
				})),
			}

		case JR_PLUS_STATION_IDS.DEFENSE_PLUS:
			return {
				stationId: 'DEFENSE_PLUS',
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				targets: getNewObjectInfo(stationData).map((objectInfo) => ({
					...getDefaultDefenseTarget(),
					...objectInfo,
				})),
			}

		case 'COMMUNICATION':
			return {
				stationId: 'COMMUNICATION',
				hitsPerStudent: oldValue,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				...getOldObjectInfo(stationData),
			}

		case 'REPAIRS':
			return {
				stationId: 'REPAIRS',
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				duration: DEFAULT_REPAIR_DURATION,
				healthAwardedPerRound: DEFAULT_REPAIR_HEALTH_PER_ROUND,
			}

		case SHARED_STATION_IDS.CREATIVE_CANVAS:
			return getCreativeCanvasStationData()

		default:
			return stationData
	}
}
export function handleJrStationChange(
	stationData: JrActivateStationData<string>,
	newStationId: JrStationId
): JrActivateStationData<string> {
	const oldProperty = getChangingProperty(stationData.stationId)
	// @ts-expect-error We want to use `oldProperty` to index `stationData`
	const oldValue: number = oldProperty in stationData ? stationData[oldProperty] : DEFAULT_VALUE

	switch (newStationId) {
		case 'TRANSPORTER':
			return {
				stationId: newStationId,
				connectionsPerStudent: oldValue,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				objectDescription:
					'objectDescription' in stationData ? stationData.objectDescription : null,
				objectName: 'objectName' in stationData ? stationData.objectName : null,
			}

		case 'POWER':
			return {
				stationId: newStationId,
				targetPower: DEFAULT_POWER,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				objectDescription:
					'objectDescription' in stationData ? stationData.objectDescription : null,
				objectName: 'objectName' in stationData ? stationData.objectName : null,
			}

		case 'TRACTOR_BEAM':
			return {
				stationId: 'TRACTOR_BEAM',
				hitsPerStudent: oldValue,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				objectDescription:
					'objectDescription' in stationData ? stationData.objectDescription : null,
				objectName: 'objectName' in stationData ? stationData.objectName : null,
				targetType: 'targetType' in stationData ? stationData.targetType : 'TARGET',
			}

		case 'DEFENSE':
			return {
				stationId: 'DEFENSE',
				hitsPerStudent: oldValue,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				objectDescription:
					'objectDescription' in stationData ? stationData.objectDescription : null,
				objectName: 'objectName' in stationData ? stationData.objectName : null,
				targetType: 'targetType' in stationData ? stationData.targetType : 'TARGET',
			}

		case 'COMMUNICATION':
			return {
				stationId: 'COMMUNICATION',
				hitsPerStudent: oldValue,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				objectDescription:
					'objectDescription' in stationData ? stationData.objectDescription : null,
				objectName: 'objectName' in stationData ? stationData.objectName : null,
			}

		case 'SCANNING':
			return {
				stationId: 'SCANNING',
				scansPerStudent: oldValue,
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				objectDescription:
					'objectDescription' in stationData ? stationData.objectDescription : null,
				objectName: 'objectName' in stationData ? stationData.objectName : null,
			}

		case 'REPAIRS':
			return {
				stationId: 'REPAIRS',
				onComplete: 'onComplete' in stationData ? stationData.onComplete : [],
				duration: DEFAULT_REPAIR_DURATION,
				healthAwardedPerRound: DEFAULT_REPAIR_HEALTH_PER_ROUND,
			}

		case SHARED_STATION_IDS.CREATIVE_CANVAS:
			// return getCreativeCanvasStationData()
			return stationData

		// TODO: implement creative canvas station for jr
		default:
			return stationData
	}
}

/**
 * ActivateStationData types are mostly the same, but there is one property that changes depending on the stationId.
 * (connectionsPerStudent, targetPower, and hitsPerStudent)
 * This function returns that changing property associated with the given stationId
 */
function getChangingProperty(stationId: string): 'connectionsPerStudent' | 'hitsPerStudent' {
	switch (stationId) {
		case 'TRANSPORTER':
			return 'connectionsPerStudent'

		default:
			return 'hitsPerStudent'
	}
}

export const EVENT_KEY_TO_STATION: {
	onTarget: typeof JR_PLUS_STATION_IDS.DEFENSE_PLUS
	onTransport: 'TRANSPORTER'
	onTractorBeam: typeof JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS
	onPower: 'POWER'
	onCommunication: 'COMMUNICATION'
} = {
	onTarget: JR_PLUS_STATION_IDS.DEFENSE_PLUS,
	onTransport: 'TRANSPORTER',
	onTractorBeam: JR_PLUS_STATION_IDS.TRACTOR_BEAM_PLUS,
	onPower: 'POWER',
	onCommunication: 'COMMUNICATION',
}
